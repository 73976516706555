@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");


.gallery {
  margin: 20px;
  padding: 10px;
  margin: 0 auto;
  background: #f2f2f2;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
}
.v-stretch {
  grid-row: span 2;
}
.h-stretch {
  grid-column: span 3;
}
.big-stretch {
  grid-row: span 2;
  grid-column: span 3;
  transition: 0.5s all ease;
}
.gallery div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-carousel {
  width: 100% !important;
  height: 70vh !important;
  object-fit: cover;
}


/* Media Querry */

@media (max-width: 560px) {
  .v-stretch {
    grid-row: span 1;
  }
  .h-stretch {
    grid-row: span 1;
  }
  .big-stretch {
    grid-column: span 1;
    grid-row: span 1;
  }
}
