@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");

.contact-main {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
.contact-left {
  width: 35%;
  background-color: #171c24;
}
.contact-left-content {
  display: flex;
  justify-content: center;
  margin-top: 3.4rem;
}
.contact-left-div {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: white;
  margin-bottom: 3rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
.map-title {
  font: normal bold 25px/1 "Montserrat";
  color: white;
  text-align: center;
  text-align: left;
}
.contact-left-map iframe {
  width: 550px;
  height: 250px;
}
.contact-icon {
  font-size: 30px;
  color: white;
}
.map-div {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
}
.contact-left-div h4 {
  font: normal bold 23px/1 "Montserrat";
  margin-bottom: 1rem;
}
.contact-left-div p {
  font: normal 15px/1 "Poppins";
  letter-spacing: 1px;
}
.contact-right {
  padding: 30px 130px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #cfd8dc;
}
.contact-input {
  margin-bottom: 2rem;
}
.contact-right h4 {
  font: normal bold 17px/1 "Poppins";
}
.contact-right-title {
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: center;
  font: normal bold 32px/1 "Montserrat";
}
.contact-right input {
  padding: 15px 10px;
  width: 400px;
  border: none;
  outline: none;
  margin: 10px 0;
}
.description {
  height: 120px;
}
::placeholder {
  font: normal 14px/1 "montserrat";
}
.contact-submit {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.contact-submit button {
  padding: 15px 65px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  font: normal bold 18px/1 "Poppins";
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #171c24;
}

/* Media Querry */

@media (max-width: 1360px) {
  .contact-main {
    flex-wrap: wrap;
    padding: 10px;
    gap: 2.5rem;
  }
  .contact-left {
    width: 100%;
  }
  .contact-right {
    width: 100%;
    padding: 10px;
  }
  .contact-right input {
    width: 100%;
    padding: 20px 0;
  }
  .contact-left-map iframe {
    width: 100%;
  }
  .contact-right-title {
    margin: 1rem;
    font: normal bold 28px/1 "Poppins";
  }
  .contact-left-div h4 {
    font: normal bold 24px/1 "Poppins";
  }
}
