@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");

.sub-product-head {
  margin-top: 5rem;
  text-align: center;
}
.sub-product-head h3 {
  font: normal normal bold 30px/1 "Montserrat";
  text-transform: uppercase;
}
.sub-product-flex img {
  height: 400px;
  width: 400px;
  object-fit: cover;
  border-radius: 5px;
  padding: 15px;

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transition: 0.4s all ease-in-out;
}
.sub-product-flex {
  height: auto;
  padding: 40px;
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
}
.product-main {
  background-color: #afcad6;
  padding-top: 10px;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.sub-product-flex img:hover {
  transform: scale(1.1);
}

@media (max-width: 1360px) {
  .sub-product-flex {
    flex-wrap: wrap;
    padding: 10px;
    height: auto;
  }
  .sub-product-flex img {
    width: 100%;
    object-fit: contain;
  }
}
