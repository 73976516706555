@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
.infra-main {
  padding: 1px 10rem;
  background-color: #cfd8dc;
}
.infra-main-title {
  font: normal normal bold 35px/1 "Montserrat";
  text-transform: uppercase;
  text-align: center;
  padding-top: 4rem;
}
.infra-sub-title {
  font: normal bold 28px/1 "Poppins";
  letter-spacing: 1px;
  text-transform: uppercase;
}
.infra-vision-title {
  font: normal bold 32px/1 "Montserrat";
  text-transform: uppercase;
}

.interior-left-content {
  font: normal bold 18px/30px "Poppins";
  margin-bottom: 15px;
}
.interior-left img {
  box-shadow: 5px 5px 10px 10px #888888;
}
.interior-right img {
  box-shadow: 5px 5px 10px 10px #888888;
}
.infra-main-flex1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 1rem 0;
  margin: 4rem;
}
.infra-icon {
  font-size: 80px;
  margin: 20px 0;
}
.infra-main-flex1 img {
  width: 600px;
  height: 350px;
  object-fit: cover;
}
.infra-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem;
  gap: 5rem;
}
.infra1-mid-image {
  width: 600px;
  height: 400px;
  box-shadow: 5px 5px 10px 10px #888888;
  object-fit: cover;
}

/* Proposition */

.proposition-flex {
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 0 90px;
  padding-bottom: 5rem;
  align-self: end;
}
.proposition-card {
  padding: 25px;
  background-color: #90a4ae;
  width: 350px;
  height: fit-content;
  display: flex;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: 0.5s all ease-in-out;
  text-transform: capitalize;
}
.proposition-card:hover {
  transform: scale(1.1);
}
.profile-icon {
  font-size: 60px;
  margin-bottom: 15px;
  /* color: black; */
}
.profile-title {
  text-align: center;
  padding: 3rem 0;
  font: normal bold 35px/1 "Montserrat";
}
.card-title {
  font: normal normal bold 22px/30px "Poppins";
  margin: 5px 0;
}
.card-content {
  font: normal normal 16px/30px "Poppins";
  margin-top: 10px;
}

/* Media Querry */

@media (max-width: 1360px) {
  .infra-main{
    padding: 10px;
  }
  .infra-main-flex1 {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 20px;
    margin: auto;
  }
  .infra-main-flex1 img {
    width: 100%;
    height: auto;
  }
  .infra1-mid-image {
    width: 100%;
    height: auto;
  }
  .infra-middle {
    flex-direction: column-reverse;
    margin: 1rem;
    flex-wrap: wrap;
  }
  .infra-main-title {
    font: normal bold 28px/1 "Poppins";
    padding: 10px 0px;
  }
  .infra-vision-title {
    font: normal bold 25px/1 "Poppins";
    text-align: center;
    margin: 1.5rem 0;
  }
  .interior-left-content {
    font: normal 17px/32px "Poppins";
    text-align: justify;
  }
  .proposition-flex {
    flex-wrap: wrap;
    padding: 10px;
    gap: 1rem;
  }
  .history-main {
    padding: 15px;
  }
  .history-title {
    font: normal bold 30px/1 "Poppins";
    margin-bottom: 2rem;
  }
  .history-para {
    font: normal 16px/30px "Poppins";
    margin-bottom: 1rem;
  }
  .founder-history-one img {
    width: 100%;
    height: auto;
  }
  .founder-history-one {
    flex-wrap: wrap;
  }
}
