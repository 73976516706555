@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");

.home-back img {
  width: 100%;
  height: 75vh;
  object-fit: cover;
  /* margin-top: 5px; */
  opacity: 0.9;
}

/* History Section */

.history-main {
  padding: 2rem 10rem;
  position: relative;
  overflow: hidden;
}

.history-title {
  font: normal bold 30px/1 "montserrat";
  text-align: center;
  padding: 2.5rem;
  margin: 0;
}
.history-para {
  font: normal normal 18px/30px "Poppins";
  text-align: left;
  padding: 5px;
}
.founder-history-one {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-bottom: 4rem;
}
.founder-history-one img {
  width: 550px;
  height: 350px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 10px #888888;
}

/* Infrastructure Section */

.infrastructure {
  padding: 30px;
  background-color: #e7e7e7;
  padding-bottom: 5rem;
}
.infra-title {
  font: normal bold 35px/1 "Montserrat";
  text-transform: uppercase;
  text-align: center;
  margin: 2rem 0;
}
.infra-main-flex {
  display: flex;
  justify-content: space-around;
}

.infra-sub-head {
  font: normal bold 20px/1 "Poppins";
  letter-spacing: 1px;
  margin: 3rem 0;
}
.infra-para {
  font: normal normal 25px/60px "Poppins";
}
.infra-image-flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.infra-image-flex img {
  width: 750px;
  height: 1100px;
  box-shadow: 5px 5px 10px 10px #888888;
  border-radius: 5px;
  object-fit: cover;
}

/* Youtube Section */

.youtube-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 5rem 4rem;
  position: relative;
  margin: 3rem;
  overflow: hidden;
}
.content {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}
.youtube-right p {
  font: normal 17px/27px "Poppins";
  width: 650px;
  color: black;
}

/* Certificaion Section */

.certification {
  padding: 30px;
  position: relative;
  overflow: hidden;
  height: max-content;
}
.background-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("../Assets/scroll.jpg");
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.4;
}

.certificate-card {
  width: 250px;
  height: 350px;
  object-fit: contain;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-image: url("../Assets/scroll2.jpg"); */
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.5;
}
.certificate-flex {
  display: flex;
  justify-content: center;
  gap: 5rem;
  padding: 3rem 0;
}
.certificate-flex img {
  width: 300px;
  height: 240px;
  padding: 15px;
  border-top-left-radius: 10%;
  border-bottom-right-radius: 10%;
  object-fit: contain;
  transition: 0.5s all ease-in;
}
.certificate-flex img:hover {
  transform: translate(-0%, -30%);
}
.home-bottom-section {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.home-bottom-flex {
  display: flex;
  padding: 40px;
  justify-content: space-between;
  height: 650px;
  padding: 3rem;
}
.bottom-card-one,
.bottom-card-two,
.bottom-card-three,
.bottom-card-four {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 400px;
  height: 300px;
  padding: 26px 30px;
  border-top-right-radius: 70px;
  transition: 0.6s all ease-in-out;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bottom-card-four,
.bottom-card-two {
  align-self: end;
}
.bottom-card-four:hover,
.bottom-card-two:hover {
  border: 1px solid #78909c;
  transform: scale(1.1);
}
.bottom-card-one:hover,
.bottom-card-three:hover {
  border: 1px solid #78909c;
  transform: scale(1.1);
}
.home-card-title {
  font: normal bold 22px/1 "Montserrat";
}
.home-card-content {
  font: normal bold 16px/25px "Montserrat";
}
.home-icon {
  font-size: 90px;
}

/* Media Querry */
@media (max-width: 1360px) {
  .infra-main-flex {
    flex-wrap: wrap;
  }
  .infra-title {
    font: normal bold 25px/1 "Poppins";
    margin: 1rem 0;
  }
  .infrastructure {
    padding: 5px;
  }
  .infra-content {
    padding: 18px;
  }
  .infra-sub-head {
    font: normal bold 22px/1 "Poppins";
  }
  .infra-para {
    font: normal normal 20px/45px "Poppins";
  }
  .infra-image-flex img {
    width: 100%;
  }
  .certification {
    padding: 10px;
  }
  .certificate-flex {
    flex-wrap: wrap;
    gap: 3rem;
    padding: 0;
  }
  .certificate-flex img {
    width: 100%;
    padding: 15px;
  }
  .home-bottom-flex {
    flex-wrap: wrap;
    height: auto;
    gap: 1rem;
    padding: 15px;
  }
  .bottom-card-four,
  .bottom-card-one,
  .bottom-card-three,
  .bottom-card-two {
    height: auto;
    width: 100%;
    padding: 10px;
  }

  /* Youtube Section */
  .youtube-section {
    flex-wrap: wrap;
    padding: 10px;
    margin: 1rem 0;
  }
  .youtube-left {
    width: fit-content;
  }
  .video {
    display: none;
  }
  .youtube-right p {
    width: 100%;
    font: normal 17px/29px "Poppins";
    text-align: center;
  }
}
