@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");

.footer {
  background-color: #78909c;
  padding: 20px 0;
  color: black;
}
.footer-main {
  display: flex;
  /* flex-wrap: wrap; */

  justify-content: space-around;
  gap: 10rem;
  opacity: 0.92;
  text-align: left;
  margin-bottom: 3rem;
  margin: 0 20px;
}
.footer-flex {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 32px;
}
.footer-title {
  font: normal bold 22px/1 "Montserrat";
  margin: 3rem 0;
  text-transform: uppercase;
}
.footer-content {
  font: normal 18px/29px "Poppins";
  letter-spacing: 0.5px;
  cursor: pointer !important;
  text-decoration: none;
}
.footer-link {
  font: normal 18px/1 "Poppins";
  letter-spacing: 1px;
  cursor: pointer !important;
  text-decoration: none;
  margin: 10 !important;
  color: black;
  transition: 0.5s all ease;
}
.footer-link:hover {
  color: white;
}
.icon {
  font-size: 40px;
}
.company-footer-title {
  font: normal normal bold 25px/1 "Montserrat";
  margin: 3rem 0;
  text-align: center;
}
.company-footer-content {
  font: normal normal 18px/30px "Poppins";
  max-width: 450px;
  text-align: justify;
}
.horizontal-line {
  width: 100%;
  margin: 3rem 0;
}
.social-icons {
  font-size: 27px;
  padding: 8px;
  border: 1px solid black;
  border-radius: 50px;
  margin-right: 10px;
  box-sizing: unset;
  margin-bottom: 40px;
  transition: 0.6s all ease;
}
.social-icons:hover {
  border: 1px solid white;
  color: white;
}
.social-links {
  display: flex;
  margin-top: 4rem;
  justify-content: center;
}
.copyright {
  font: normal normal bold 17px/1 "Montserrat";
  letter-spacing: 1px;
  text-align: center;
}
.copyright a {
  cursor: pointer;
  color: white;
  text-decoration: underline;
  transition: 0.5s all ease;
}
.copyright a:hover {
  color: greenyellow;
}
.foot-links {
  display: grid;
  row-gap: 1.4rem;
}
a {
  text-decoration: none;
  color: white;
}

/* Media Querry */
@media (max-width: 1360px) {
  .footer {
    padding: 2px;
  }
  .footer-main {
    flex-wrap: wrap;
    gap: 1rem;
    text-align: center;
  }
  .footer-content {
    font: normal 16px/24px "Poppins";
    text-align: left;
  }
  .footer-flex {
    gap: 15px;
    margin: 10px 0;
  }
  .footer-title {
    margin: 1.5rem 0;
  }
  .company-footer-content {
    font: normal 17px/35px "Poppins";
  }
  .company-footer-title {
    font: normal bold 28px/1 "Poppins";
    margin: 2rem 0;
  }
  .social-links {
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  .copyright {
    font: normal 17px/30px "Poppins";
  }
  .foot-links {
    text-align: left;
  }
  .icon {
    font-size: 30px;
  }
}
