@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,500&display=swap");

.products {
  padding: 2rem 0;
  margin: 0 10rem;
}
.product-head {
  text-align: center;
  margin-bottom: 3rem;
}
.product-main-title {
  font: normal normal bold 30px/1 "Montserrat";
  text-transform: uppercase;
  text-align: center;
}

.product-main-content {
  font: normal normal 19px/1 "Poppins";
  margin-top: 2rem;
}
.product-sub-title {
  font: normal bold 32px/1 "Poppins";
}
.product-image-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 30px;
  flex-wrap: wrap;
  margin: 3rem 0;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.product-card-one,
.product-card-two,
.product-card-three {
  text-align: center;
  height: auto;
  position: relative;
}
.product-image-flex img {
  width: 400px;
  height: 350px;
  background-color: #78909c;
  padding: 8px;
  object-fit: cover;
}
.product-caption {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  color: #6d4c41;
  font: normal bold 30px/1 "Montserrat";
  opacity: 0;
  transition: all 0.5s ease;
}
.product-caption:hover {
  opacity: 1;
  color: #37474f;
}

/* Media Querry */

@media (max-width: 1360px) {
  .product-head {
    margin-bottom: 1rem;
  }
  .products {
    padding: 10px;
    margin: 0 2rem;
  }
  .product-main-title {
    font: normal bold 25px/1 "Poppins";
  }
  .product-main-content {
    font: normal 18px/36px "Poppins";
  }
  .product-image-flex {
    width: auto;
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
  }
  .product-sub-title {
    font: normal bold 25px/1 "Poppins";
  }
  .product-image-flex img {
    width: 100%;
    height: 300px;
  }
}
